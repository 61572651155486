export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/users/edit.vue'),
    meta: {
      resource: 'Auth',
      active: true,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/p_login',
    name: 'p_login',
    component: () => import('@/views/PLogin.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: false,
    },
  },



  /** accommodation start * */



  /** awards start */

  {
    path: '/awards-lists',
    name: 'awards-lists',
    component: () => import('@/views/awards/lists.vue'),
    meta: {
      // layout: 'full',
      resource: 'Auth',
      active: true,
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/awards-appraise',
    name: 'awards-appraise',
    component: () => import('@/views/awards/appraise.vue'),
    meta: {
      // layout: 'full',
      resource: 'Auth',
      active: true,
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/awards-appraise-where',
    name: 'awards-appraise-where',
    component: () => import('@/views/awards/appraiseWhere.vue'),
    meta: {
      // layout: 'full',
      resource: 'Auth',
      active: true,
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/awards-vote',
    name: 'awards-vote',
    component: () => import('@/views/awards/affirm.vue'),
    meta: {
      // layout: 'full',
      resource: 'Auth',
      active: true,
      redirectIfLoggedIn: true,
    },
  },






  /** awards end */


  {
    path: '/error-404',
    component: () => import('@/views/error/Error404.vue'),
    name: 'error-404',
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
